import { Alert, Descriptions, Drawer, Form, Modal, Table } from "antd";
import "./styles.less";
import { useState } from "react";
import { convertToLabel, deviceSettingsHistoryColumns, hasObjectChangesHelper } from "../../_helpers";
import FormActionBar from "../../_components/FormActionBar";
import MaintenanceModeBar from "../../_components/MaintenanceModeBar";
import DeviceStateKeyFormItem from "_components/DeviceStateKeyFormItem";
import HelperModal from "../../_components/HelperModal";
import * as permConsts from "../../_constants/permissionConstants";
import { usePermissions } from "../../_hooks/permissions.hook";

const DeviceStatesView = ({
                              deviceStates,
                              deviceStatesHistory,
                              deviceStatesHistoryLoading,
                              deviceStatesUpdating,
                              updateDeviceStatesHandler,
                              getDeviceStatesHistoryHandler,
                              partyId
                          }) => {
    const [historyVisible, setHistoryVisible] = useState(false);
    const [showHelpModal, setShowHelpModal] = useState(false);

    const [updatePayload, setUpdatePayload] = useState(deviceStates);
    const [hasChanges, setHasChanges] = useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [confirmStateModalVisible, setConfirmStateModalVisible] = useState(false);
    const [selectedForDelete, setSelectedForDelete] = useState({id: null, key: null});
    const initialValues = deviceStates ? {...deviceStates} : {};
    const permissions = usePermissions();

    const handleValuesChange = (newChange, key) => {
        let payloadCopy = {...updatePayload};
        payloadCopy[key] = newChange;

        setUpdatePayload(payloadCopy);
        let keyHasChanges = hasObjectChangesHelper(initialValues, payloadCopy);
        setHasChanges(keyHasChanges);
    };

    const handleInput = (payload) => {
        let payloadCopy = {...updatePayload};

        if (payload.target.name === "message") {
            payloadCopy["maintenanceModeEnabledMessage"] = payload.target.value;
        }
        if (payload.target.name === "workOrderNumber") {
            payloadCopy["maintenanceModeEnabledWorkOrderNumber"] = payload.target.value;
        }

        setUpdatePayload(payloadCopy);
    }

    const onHistoryClick = () => {
        getDeviceStatesHistoryHandler();
        setHistoryVisible(true);
    }

    const onHelpClick = () => {
        setShowHelpModal(true);
    }

    const helpModalContent = <>
        <Table
            size={"middle"}
            ordered={true}
            pagination={false}
            dataSource={
                [
                    {
                        "state": "Available",
                        "description": "If disabled, charger will remain functional, but will appear as UNKNOWN only for the EA MSP. This does not affect the status as it appears in the CPO."
                    },
                    {
                        "state": "Maintenance Mode",
                        "description": "If enabled, charger will remain functional, but will appear as RESERVED in the CPO network."
                    },
                    {
                        "state": "Inoperative",
                        "description": "If enabled, charger will no longer remain functional, and will report its status as being INOPERATIVE."
                    }
                ]
            }
            columns={
                [
                    {
                        "title": "State",
                        "dataIndex": "state",
                        "key": "state"
                    },
                    {
                        "title": "Description",
                        "dataIndex": "description",
                        "key": "description"
                    }
                ]
            }>
        </Table>
    </>

    const onDeleteConfirm = () => {
        const changeIndex = updatePayload.findIndex((val) => val.id === selectedForDelete.id);
        const payloadCopy = {...updatePayload};
        payloadCopy.splice(changeIndex, 1);

        setUpdatePayload(payloadCopy);

        onSave(payloadCopy);

        setDeleteModalVisible(false);
        setSelectedForDelete({id: null, key: null});
    }

    const onSave = (payload) => {
        const finalPayload = {...deviceStates, ...payload, partyId};
        updateDeviceStatesHandler(finalPayload);
    }

    function filterSaveLabel(key, value) {
        if (key.includes('maintenanceModeEnabled')) {
            return !(value === null || value.toString().match(/^ *$/) !== null);
        }
        return !key.includes('chargeboxId') && !key.includes('partyId');
    }

    function convertToSaveLabel(key, value) {
        if (!key.includes('maintenanceModeEnabled')) {
            return value.toString() === 'true' ? "Enabled" : "Disabled";
        }
        return value.toString().trim();
    }

    return (
        <>
            <Modal
                okText="Confirm"
                title="Confirm Deletion"
                visible={deleteModalVisible}
                onOk={() => onDeleteConfirm()}
                onCancel={() => setDeleteModalVisible(false)}
            >
                <p>
                    Are you sure you want to delete state <b>{selectedForDelete.key}</b>? This will be effective
                    immediately.
                </p>
            </Modal>
            <Modal
                okText="Confirm"
                title="Confirm State Change"
                visible={confirmStateModalVisible}
                onOk={() => onSave(updatePayload)}
                onCancel={() => setConfirmStateModalVisible(false)}
            >
                <p>Are you sure you want to update the state to:</p>
                <Descriptions column={1} bordered={true}>
                    {Object.entries(updatePayload)
                        .filter(([payloadKey, payloadValue]) => filterSaveLabel(payloadKey, payloadValue))
                        .map(([payloadKey, payloadValue]) =>
                            (<Descriptions.Item
                                    label={convertToLabel(payloadKey)}>{convertToSaveLabel(payloadKey, payloadValue)}</Descriptions.Item>
                            ))}
                </Descriptions>
            </Modal>
            <div className="statesContainer">
                <Form
                    initialValues={initialValues}
                    onFinish={() => setConfirmStateModalVisible(true)}
                    layout="vertical"
                >
                    {deviceStates ? (
                        Object.keys(deviceStates)
                            .filter(key => {
                                return key !== 'chargeboxId' && key !== 'partyId' && !key.includes('maintenanceModeEnabled')
                                    // disabling inoperative status toggle at NOC request 02/09/23
                                    && !key.includes('inoperative')
                            }).filter(key => {
                                switch (key) {
                                    case 'available':
                                        return permissions.checkPermission(permConsts.components.devices, permConsts.actions.deviceStateAvailable);
                                    case 'freevend':
                                        return permissions.checkPermission(permConsts.components.devices, permConsts.actions.deviceStateFreevend);
                                    case 'maintenanceMode':
                                        return permissions.checkPermission(permConsts.components.devices, permConsts.actions.deviceStateMaintenanceMode);
                                    case 'pncEnabled':
                                        return permissions.checkPermission(permConsts.components.devices, permConsts.actions.deviceStatePncEnabled);
                                    default:
                                        return true;
                                }
                            })
                            .map(key => {
                                return (
                                    <DeviceStateKeyFormItem
                                        itemKey={key}
                                        key={key}
                                        value={deviceStates[key]}
                                        handleValuesChange={handleValuesChange}
                                    />
                                );
                            })
                    ) : (
                        <Alert message="No device state" type="info"/>
                    )}
                    {
                        permissions.checkPermission(permConsts.components.devices, permConsts.actions.deviceStateMaintenanceMode) &&
                            <MaintenanceModeBar
                                onChange={handleInput}
                            />
                    }
                    <FormActionBar
                        disabled={!hasChanges || deviceStatesUpdating}
                        onHistoryClick={onHistoryClick}
                        onHelpClick={onHelpClick}
                        disableEdit={true}
                    />
                </Form>
            </div>

            <Drawer
                title="History"
                placement="right"
                width={'60%'}
                onClose={() => setHistoryVisible(false)}
                visible={historyVisible}
            >
                <Table
                    dataSource={deviceStatesHistory}
                    rowKey={(record) => record.id}
                    columns={deviceSettingsHistoryColumns}
                    pagination={false}
                    loading={deviceStatesHistoryLoading}
                />
            </Drawer>
            <HelperModal modalContent={helpModalContent} setShowInfoModal={setShowHelpModal} showInfoModal={showHelpModal}/>
        </>
    );
};

export default DeviceStatesView