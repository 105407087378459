import { Tag } from "antd";
import { formatFullYearMonths } from "./date";

/**
 * Helpers for any device setting (states, configs, global keys, etc.) related forms
 */

export const hasObjectChangesHelper = (initialValues, changedValues) => {
  for (let key in initialValues) {
    if (initialValues[key] !== changedValues[key]) {
      return true;
    }
  }

  return false;
};

export const deviceConfigHistoryColumns = [
  {
    title: "Time",
    dataIndex: "timestamp",
    key: "timestamp",
    render: (timestamp) => formatFullYearMonths(timestamp, false),
  },
  {
    title: "Username",
    dataIndex: "userName",
    key: "userName"
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    render: (action) => <Tag color={action === "SAVE" ? "green" : "red"}>{action}</Tag>
  },
  {
    title: "Configurations Affected",
    dataIndex: "configurations",
    key: "configurations",
    render: (_, record) => {
      const configurations = record.configurations;
      return (
        configurations && configurations.length > 0 ? configurations.map((config, index) => <Tag key={index}>{`${config.changeKey}: ${config.changeValue}`}</Tag>) : <></>
      )
    }
  },
];

const renderLogic = (value) => {
  if (typeof value === "boolean") return value ? "Enabled" : "Disabled";
  if (typeof value === "string" || typeof value === "number") return value === "" || !value ? "N/A" : value;
  return value;
};

export const deviceSettingsHistoryColumns = [
  {
    title: "Time",
    dataIndex: "timestamp",
    key: "timestamp",
    render: (timestamp) => formatFullYearMonths(timestamp, false)
  },
  {
    title: "Username",
    dataIndex: "userName",
    key: "userName"
  },
  {
    title: "Available",
    dataIndex: "available",
    key: "available",
    render: renderLogic,
  },
  {
    title: "Freevend",
    dataIndex: "freevend",
    key: "freevend",
    render: renderLogic,
  },
  {
    title: "PncEnabled",
    dataIndex: "pncEnabled",
    key: "pncEnabled",
    render: renderLogic,
  },
  {
    title: "MaintenanceMode",
    dataIndex: "maintenanceMode",
    key: "maintenanceMode",
    render: renderLogic,
  },
  {
    title: "MaintenanceMode Message",
    dataIndex: "maintenanceModeEnabledMessage",
    key: "maintenanceModeEnabledMessage",
    ellipsis: false,
  },
  {
    title: "MaintenanceMode WorkOrderNumber",
    dataIndex: "maintenanceModeEnabledWorkOrderNumber",
    key: "maintenanceModeEnabledWorkOrderNumber",
    ellipsis: false,
  },
];
