import {Button, Col, Collapse, Descriptions, Form, Input, Row, Spin, Table, Typography,} from 'antd';
import {formatFullYearMonths} from '_helpers';
import {fieldNames, portFieldNames} from "./fieldNames";
import StatusTag from "../../_components/StatusTag";
import {columns as messagesColumns} from './deviceMessagesColumns';
import {columns as portsColumns} from './devicePortsColumns';
import {columns as certificatesColumns} from './deviceCertificatesColumns';
import './styles.less';
import DevicePortStatusDialog from "./DevicePortStatusDialog";
import {useState} from "react";
import {LinkOutlined} from "@ant-design/icons";
import {usePermissions} from "../../_hooks/permissions.hook";
import * as permConsts from "../../_constants/permissionConstants";
import EditableTags from '_components/EditableTags';
import OperationalStatusPanel from "./OperationalStatusPanel/OperationalStatusPanel";
import {formatBoolean} from "../../_helpers/boolean";

const {Panel} = Collapse;
const {TextArea} = Input;

const DeviceView = ({
                        device,
                        deviceCertificates,
                        loading,
                        evsePagination,
                        evse,
                        evseUpdating,
                        onChange,
                        handleAddEvseMessage,
                        deleteTag,
                        getTags,
                        addTagToDevice,
                        tagTokenLoading,
                        deviceChargingGroup,
                        togglePortStatus
                    }) => {
    const initialValues = evse ? {...evse} : {};
    const descriptionList = [];
    const [isTogglePortStatus, setIsTogglePortStatus] = useState(false);
    const permissions = usePermissions();
    let tags = [];

    const onFinish = (payload) => {
        handleAddEvseMessage(payload.message);
    };

    const descriptionView = loading ? <Spin/> :
        <Descriptions column={2} bordered={true}>
            {descriptionList}
        </Descriptions>;


    const isTimeField = (fieldName) => {
        const lowercase = fieldName.toLowerCase();
        return lowercase.includes('timestamp') ||
            lowercase.includes('lastupdated') ||
            lowercase.includes('lastpublished');
    }

    const isBooleanField = (fieldName) => {
        return fieldName === 'maintenanceMode' || fieldName === 'derated';
    }

    // id is not necessary to show and ports is an un-renderable list
    const fieldShouldBeShown = (fieldName) => {
        const lowercase = fieldName ? fieldName.toLowerCase() : "";
        // TODO display evses and connectors in details PR
        return !['ports', 'id', 'evses', 'connectors'].includes(lowercase);
    }

    const onTogglePortStatus = (values) => {
        togglePortStatus(values[portFieldNames.connectorIds], values[portFieldNames.status], values[portFieldNames.message]);
        setIsTogglePortStatus(false);
    }

    const canUpdateDevicePortStatus = permissions.checkPermission(permConsts.components.devices, permConsts.actions.devicePortStatus);
    const canUpdateDeviceTags = permissions.checkPermission(permConsts.components.devices, permConsts.actions.deviceTags);

    if (device) {
        for (const [key, value] of Object.entries(device)) {
            const label = fieldNames[key];
            if (key.toLowerCase().includes('status')) {
                descriptionList.push(
                    <Descriptions.Item
                        className="description-status-tag"
                        label={label}
                        key={key}
                    >
                        <StatusTag status={value}/>
                    </Descriptions.Item>
                );
            } else if (isTimeField(key)) {
                descriptionList.push(
                    <Descriptions.Item key={key} label={label}>
                        {formatFullYearMonths(value)}
                    </Descriptions.Item>
                );
            } else if (isBooleanField(key)) {
                descriptionList.push(
                    <Descriptions.Item key={key} label={label}>
                        {formatBoolean(value)}
                    </Descriptions.Item>
                );
            } else if (key === "tags") {
                tags = value;
            } else if (fieldShouldBeShown(key)) {
                descriptionList.push(
                    <Descriptions.Item key={key} label={label}>
                        {value}
                    </Descriptions.Item>
                );
            }
        }

        // station id has to be first in the list
        descriptionList.unshift(
            <Descriptions.Item key="stationIds" label="Station ID(s)">
                {!device.evses || device.evses.length === 0 ? "N/A" :
                    device.evses.map(evse =>
                      (<StatusTag
                        status={evse.operationalStatus}
                        hoverContent={`${evse.evseUid} (${evse.operationalStatus ?? 'UNKNOWN'})`}
                        overrideText={evse.stationId}
                      ></StatusTag>)
                    )
                }
            </Descriptions.Item>
        );

        // operational status can be last
        descriptionList.push(
            <Descriptions.Item key="operationalStatuses" label="Operational Status(es)">
                {!device.evses || device.evses.length === 0 ? "N/A" :
                    device.evses.map(evse =>
                        (<StatusTag
                            status={evse.operationalStatus}
                            hoverContent={evse.evseUid}>
                        </StatusTag>)
                    )
                }
            </Descriptions.Item>
        )

    }

    const ChargingGroupDetail = () => {
        const nameView = deviceChargingGroup ?
            <a href={`/charging-groups/${deviceChargingGroup.id}`} target={"_blank"} rel="noopener noreferrer">
                <span>{deviceChargingGroup.name} <LinkOutlined/></span></a> : 'N/A';
        const tariffIdView = deviceChargingGroup ?
            <a href={`${process.env.REACT_APP_PRICING_MANAGEMENT_URL}/admin/content/tariffs/${deviceChargingGroup.tariffId}`}
               target={"_blank"} rel="noopener noreferrer"><span>{deviceChargingGroup.tariffId}
                <LinkOutlined/></span></a> : 'N/A';

        return (
            <div className="chargingGroupDetailView">
                <Descriptions column={2} bordered={true}>
                    <Descriptions.Item key={'charging-group-name'} label={'Name'}>{nameView}</Descriptions.Item>
                    <Descriptions.Item key={'charging-group-desc'}
                                       label={'Description'}>{deviceChargingGroup.description}</Descriptions.Item>
                    <Descriptions.Item key={'charging-group-tariff'} label={'Tariff'}>{tariffIdView}</Descriptions.Item>
                </Descriptions>
            </div>
        );
    }

    const MessageForm = () => {
        return (
            <Row>
                <Col span={24}>
                    <Table
                        className="messageTable"
                        columns={messagesColumns}
                        dataSource={evse}
                        rowKey='id'
                        pagination={evsePagination}
                        onChange={onChange}
                        tableLayout='fixed'
                    />
                </Col>
                {canUpdateDevicePortStatus &&
                    <Col span={12}>
                        <Form
                            initialValues={initialValues}
                            onFinish={onFinish}
                            layout="vertical"
                        >
                            <Form.Item
                                name="message"
                                rules={[{max: 500}]}
                            >
                                <TextArea rows={4} disabled={evseUpdating}/>
                            </Form.Item>

                            <Form.Item wrapperCol={{span: 16}}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    disabled={evseUpdating}
                                    loading={evseUpdating}
                                >
                                    Add Message
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>}
            </Row>
        );
    }

    const PortsTable = () => {
        return (
            <Row>
                <Col span={24}>
                    <Table
                        className="portsTable"
                        columns={portsColumns}
                        dataSource={device?.ports}
                        rowKey={record => `${record.evseNumber}-${record.id}`}
                        pagination={false}
                        tableLayout='fixed'
                    />
                </Col>
            </Row>
        );
    }

    const CertificatesTable = () => {
        return (
            <Row>
                <Col span={24}>
                    <Table
                        className="certificatesTable"
                        columns={certificatesColumns}
                        dataSource={deviceCertificates}
                        rowKey='id'
                        pagination={false}
                        tableLayout='fixed'
                    />
                </Col>
            </Row>
        );
    }

    return (
        <>
            <DevicePortStatusDialog
                open={isTogglePortStatus}
                handleClose={() => setIsTogglePortStatus(false)}
                togglePortStatus={onTogglePortStatus}
                device={device}
            />
            <div className="deviceDetailContainer">
                <div className="descriptionDetailContainer">
                    <Collapse defaultActiveKey={['1', '2', '3', '4', '5', '6', '7']} ghost>
                        <Panel header={<Typography.Text strong>Details</Typography.Text>} key="1">
                            {device && descriptionView}
                        </Panel>
                        <Panel header={<Typography.Text strong>Tags</Typography.Text>} key="6">
                            <EditableTags
                                tags={tags}
                                deleteTag={deleteTag}
                                getTags={getTags}
                                addTagToDevice={addTagToDevice}
                                canUpdateDeviceTags={canUpdateDeviceTags}
                                tagTokenLoading={tagTokenLoading}
                            />
                        </Panel>
                        <Panel header={<Typography.Text strong>Operation Status Log</Typography.Text>} key="7">
                            <OperationalStatusPanel device={device}/>
                        </Panel>
                        <Panel header={<Typography.Text strong>Message</Typography.Text>} key="2">
                            <MessageForm/>
                        </Panel>
                        <Panel header={<Typography.Text strong>Charging Group</Typography.Text>} key="3">
                            <ChargingGroupDetail/>
                        </Panel>
                        <Panel header={<Typography.Text strong>Ports</Typography.Text>} key="4">
                            <PortsTable/>
                            {canUpdateDevicePortStatus &&
                                <Button
                                    type="primary"
                                    onClick={() => setIsTogglePortStatus(true)}>
                                    Change Port Status
                                </Button>
                            }
                        </Panel>
                        <Panel header={<Typography.Text strong>Charger ISO Certificates</Typography.Text>} key="5">
                            <CertificatesTable/>
                        </Panel>
                    </Collapse>
                </div>
            </div>
        </>
    );
};

export default DeviceView;
