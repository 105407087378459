import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { configurationService } from "../../_services/configuration";

export const getDeviceConfiguration = createAsyncThunk(
  'getDeviceConfiguration',
  async ({chargeboxId, auth0Token}) => {
    return configurationService.getDeviceConfiguration(chargeboxId, auth0Token);
  }
);

export const getDeviceConfigurationHistory = createAsyncThunk(
  'getDeviceConfigurationHistory',
  async({chargeboxId, auth0Token}) => {
    return configurationService.getDeviceConfigurationHistory(chargeboxId, auth0Token);
  }
);

const configurationSlice = createSlice({
  name: 'config',
  initialState: {
    configuration: [],
    configurationHistory: [],
    loading: true,
    historyLoading: true,
  },
  reducers: {},
  extraReducers: {
    [getDeviceConfiguration.fulfilled]: (state, action) => {
      state.configuration = action.payload;
      state.loading = false;
    },
    [getDeviceConfiguration.pending]: (state, action) => {
      state.loading = true;
    },
    [getDeviceConfiguration.rejected]: (state, action) => {
      state.configuration = [];
      state.loading = false;
    },
    [getDeviceConfigurationHistory.fulfilled]: (state, action) => {
      state.configurationHistory = action.payload;
      state.historyLoading = false;
    },
    [getDeviceConfigurationHistory.pending]: (state, action) => {
      state.historyLoading = true;
    },
    [getDeviceConfigurationHistory.rejected]: (state, action) => {
      state.configurationHistory = [];
      state.historyLoading = false;
    }
  }
})

export default configurationSlice.reducer;