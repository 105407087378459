import { get, post } from "../_helpers";

const basePath = 'configurations';

function getDeviceConfiguration(chargeboxId, auth0Token) {
  return get({ path: `${basePath}/${chargeboxId}` }, auth0Token);
}

function getDeviceConfigurationHistory(chargeboxId, auth0Token) {
  return get({ path: `${basePath}/history/${chargeboxId}` }, auth0Token);
}

function saveDeviceConfiguration(chargeboxId, body, auth0Token) {
  return post({ path: `${basePath}/${chargeboxId}`, body: body ,opts: { headers: { "Content-Type": "application/json" } } }, auth0Token);
}

function deleteDeviceConfiguration(chargeboxId, body, auth0Token) {
  return post({ path: `${basePath}/delete/${chargeboxId}`, body: body ,opts: { headers: { "Content-Type": "application/json" } } }, auth0Token);
}

export const configurationService = {
  getDeviceConfiguration,
  getDeviceConfigurationHistory,
  saveDeviceConfiguration,
  deleteDeviceConfiguration
}