import {Button, Col, Descriptions, Drawer, Form, Input, Modal, Row, Table, Tooltip} from "antd";
import { useState } from "react";
import FormActionBar from "../../_components/FormActionBar";
import {CloseOutlined, FieldStringOutlined, KeyOutlined, PlusOutlined} from "@ant-design/icons";
import {deviceConfigHistoryColumns} from "../../_helpers";
import HelperModal from "../../_components/HelperModal";
import {errorNotification, openNotification} from "../../_components/Notification";

const DeviceConfigurationView = ({
                                   device,
                                   deviceConfig,
                                   deviceConfigHistory,
                                   getConfigurationHistory,
                                   deviceConfigHistoryLoading,
                                   getConfigurationHandler,
                                   saveConfigurationHandler,
                                   deleteConfigurationHandler,
                                   triggerBootHandler
                                }) => {
  const CHANGE_KEY = "changeKey";
  const CHANGE_VALUE = "changeValue";
  const DELIMITER = "||";
  const NEW_ID = "new-id";

  // helpers
  const formatConfigMapId = (id, value) => `${id}${DELIMITER}${value}`;

  const formatConfigForStorage = (configList) => configList ? configList.reduce((configMap, c) => {
    configMap[c.id] = {...c};
    return configMap;
  }, {}) : {};

  const [form] = Form.useForm();
  const originalDeviceConfig = formatConfigForStorage(deviceConfig);
  const [currentConfigsMap, setCurrentConfigsMap] = useState(formatConfigForStorage(deviceConfig));

  const [historyOpen, setHistoryOpen] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [updatedConfigsMap, setUpdatedConfigsMap] = useState({});
  const [disableSave, setDisableSave] = useState(true);
  const [deletedConfigsMap, setDeletedConfigsMap] = useState({});
  const [selectedForDelete, setSelectedForDelete] = useState(null);

  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [confirmSaveModalOpen, setConfirmSaveModalOpen] = useState(false);

  const formatConfigForForm = () => Object.entries(currentConfigsMap).reduce((formMap, [configId, configMap]) => {
    const changeKeyMapId = formatConfigMapId(configId, CHANGE_KEY);
    const changeKeyValueId = formatConfigMapId(configId, CHANGE_VALUE);
    formMap[changeKeyMapId] = configMap[CHANGE_KEY];
    formMap[changeKeyValueId] = configMap[CHANGE_VALUE];
    return formMap;
  }, {});

  const handleValuesChange = (newChange, configItem, field) => {
    const updatedConfigItem = {...configItem, [field]: newChange};
    const currentCopy = {...currentConfigsMap, ...formatConfigForStorage([updatedConfigItem])};
    setCurrentConfigsMap(currentCopy);

    const originalConfig = originalDeviceConfig[configItem.id];
    const updateCopy = {...updatedConfigsMap};

    // change isn't actually a change since it matches with original value
    if (originalConfig && originalConfig[CHANGE_KEY] === updatedConfigItem[CHANGE_KEY] &&
      originalConfig[CHANGE_VALUE] === updatedConfigItem[CHANGE_VALUE]) {
      delete updateCopy[configItem.id];
    } else {
      updateCopy[configItem.id] = updatedConfigItem;
    }

    setUpdatedConfigsMap(updateCopy);
    setDisableSave(shouldSaveBeDisabled(updateCopy, deletedConfigsMap, currentConfigsMap));
  }

  const reset = () => {
    setUpdatedConfigsMap({});
    setDeletedConfigsMap({});
    setCurrentConfigsMap(formatConfigForStorage(deviceConfig));
    setDisableSave(true);
  }

  const onAddClick = () => {
    const newId = `${NEW_ID}${Date.now().toString()}`;
    const currentCopy = {...currentConfigsMap};

    setCurrentConfigsMap({
      ...currentCopy,
      ...{[`${newId}`]: {
          id: newId,
          chargeBoxId: device?.chargeboxId,
          [CHANGE_KEY]: null,
          [CHANGE_VALUE]: null,
        }
      }
    });

    form.setFieldsValue(formatConfigForForm());
    setDisableSave(true);
  }

  const onUpdateClick = () => {
    const previousIsEditing = isEditing;

    setIsEditing(!previousIsEditing);

    // means we went from false to true
    if (previousIsEditing === false) {
      form.setFieldsValue(formatConfigForForm());
    } else {
      form.resetFields();
      reset();
    }
  }

  const onHistoryClick = () => {
    getConfigurationHistory();
    setHistoryOpen(true);
  }

  const onDeleteClick = (config) => {
    setConfirmDeleteModalOpen(true);
    setSelectedForDelete({...config});
  }

  const onDeleteConfirm = () => {
    let deleteCopy;
    // only store deletes if they're for existing configs
    if (!selectedForDelete.id.startsWith(NEW_ID)) {
      deleteCopy = {...deletedConfigsMap, ...{[selectedForDelete.id]: {...selectedForDelete}}};
      setDeletedConfigsMap(deleteCopy);
    }

    const currentCopy = {...currentConfigsMap};
    delete currentCopy[selectedForDelete.id];
    setCurrentConfigsMap(currentCopy);

    // in case this was also an updated field that was later deleted
    const updateCopy = {...updatedConfigsMap};
    delete updateCopy[selectedForDelete.id];
    setUpdatedConfigsMap(updateCopy);

    form.setFieldsValue(formatConfigForForm());

    setConfirmDeleteModalOpen(false);
    setSelectedForDelete(null);
    setDisableSave(shouldSaveBeDisabled(updateCopy, deleteCopy ?? deletedConfigsMap, currentCopy));
  }

  const formatRequest = (configMap) => Object.entries(configMap)
      .reduce((request, [configId, config]) => [...request, {
        ...(configId.startsWith(NEW_ID) ? {} : {id: configId} ),
        chargeBoxId: device?.chargeboxId,
        [CHANGE_KEY]: config[CHANGE_KEY],
        [CHANGE_VALUE]: config[CHANGE_VALUE]
      }], []);

  const onSaveConfirm = (triggerBoot) => {
    const toExecute = [];

    if (Object.keys(updatedConfigsMap).length > 0) {
      toExecute.push(saveConfigurationHandler(formatRequest(updatedConfigsMap)));
    }

    if (Object.keys(deletedConfigsMap).length > 0) {
      toExecute.push(deleteConfigurationHandler(formatRequest(deletedConfigsMap)));
    }

    Promise.all(toExecute)
      .then(() => {
        if (triggerBoot) {
          triggerBootHandler({
            requestedMessage: "BootNotification",
            remoteActionType: "IMMEDIATE",
            getAll: false,
            deselectedRows: []
          });
          openNotification("Boot Notification Triggered", `Boot Notification was triggered on charger ${device?.chargeboxId}.`);
        }

        getConfigurationHandler();
        openNotification("Device configuration updated", "Configuration has been updated successfully.");
      })
      .catch((err) =>
        errorNotification("Could not update configuration", err)
      );

    setConfirmSaveModalOpen(false);
    setIsEditing(false);
    reset();
  }

  const renderConfigurationItem = (configId, configMap) => {
    const changeKeyMapId = formatConfigMapId(configId, CHANGE_KEY);
    const changeValueMapId = formatConfigMapId(configId, CHANGE_VALUE);

    return (
      <Row key={configId} className="configuration-item" gutter={16}>
        <Col span={7}>
          {isEditing ?
            <Form.Item
              name={changeKeyMapId}
              className={"edit-form-label"}
              rules={[{required: true}]}
            >
              <Input
                prefix={<KeyOutlined />}
                onChange={(change) => handleValuesChange(change.target.value, configMap, CHANGE_KEY)}
              />
            </Form.Item> :
            <span><b>{configMap[CHANGE_KEY]}</b></span>
          }
        </Col>
        <Col span={isEditing ? 16 : 17}>
          {isEditing ?
            <Form.Item
              name={changeValueMapId}
              className={"edit-form-label"}
              rules={[{required: true}]}
            >
              <Input
                prefix={<FieldStringOutlined />}
                onChange={(change) => handleValuesChange(change.target.value, configMap, CHANGE_VALUE)}
              />
            </Form.Item> :
            <Tooltip placement="top" title={configMap[CHANGE_VALUE]}><span>{configMap[CHANGE_VALUE]}</span></Tooltip>
          }
        </Col>
        {isEditing &&
          <Col span={1}>
            <Button
              size="small"
              type="text"
              danger="true"
              icon={<CloseOutlined />}
              onClick={() => onDeleteClick(configMap)}
            />
          </Col>
        }
      </Row>
    );
  };

  const renderConfirmationDescriptionItem = (config) => (
    <Descriptions.Item key={config[CHANGE_KEY]} label={config[CHANGE_KEY]}>
      {config[CHANGE_VALUE]}
    </Descriptions.Item>
  )

  const shouldSaveBeDisabled = (updatedConfigs, deletedConfigs, currentConfigs) => {
    // disable save if:
    // no updates AND no deletes
    // OR there are any empty inputs
    return (Object.keys(updatedConfigs).length === 0 && Object.keys(deletedConfigs).length === 0) ||
      Object.values(currentConfigs).filter(config => !config[CHANGE_KEY] || !config[CHANGE_VALUE]).length > 0;
  }

  const helpModalContent = <div>
    <p>Device configurations are stored in the CPO and determine how the charger behaves. Some configurations are
  for the server only, while others are recognized by the charger themselves.</p>
    <p>Most configuration values are set during commissioning. However, you may use this view to manually update an individual charger's
      configuration as required</p></div>

  return (
    <>
      <Modal
        okText="Confirm Changes"
        title="Confirm Configuration Changes"
        open={confirmSaveModalOpen}
        footer={[
          <Button type="danger" onClick={() => setConfirmSaveModalOpen(false)}>Cancel</Button>,
          <Button type="default" onClick={() => onSaveConfirm(false)}>Confirm</Button>,
          <Button type="primary" onClick={() => onSaveConfirm(true)}>Confirm + Trigger</Button>
        ]}
      >
        <p>
          Are you sure you want make the following changes to this configuration?
          (You may trigger a boot notification if you select Confirm + Trigger.
          Otherwise, you may save without trigger if you select Confirm).
        </p>
        {
          Object.keys(updatedConfigsMap).length > 0 &&
          <Descriptions title="Updated" column={1} bordered={true}>
            {
              Object.values(updatedConfigsMap).map((updatedConfig) =>
                renderConfirmationDescriptionItem(updatedConfig)
              )
            }
          </Descriptions>
        }
        <br/>
        {
          Object.keys(deletedConfigsMap).length > 0 &&
          <Descriptions title="Deleted" column={1} bordered={true}>
            {
              Object.values(deletedConfigsMap).map((deletedConfig) =>
                renderConfirmationDescriptionItem(deletedConfig)
              )
            }
          </Descriptions>
        }
      </Modal>

      <Modal
        okText="Confirm Delete"
        title="Confirm Delete Configuration"
        open={confirmDeleteModalOpen}
        onOk={onDeleteConfirm}
        onCancel={() => setConfirmDeleteModalOpen(false)}
      >
        <p>Are you sure you want to remove this configuration? Actual deletion will not occur until you save your updates.</p>
        <Descriptions column={2} bordered={true}>
          <Descriptions.Item key={selectedForDelete?.[CHANGE_KEY] ?? "N/A"} label={selectedForDelete?.[CHANGE_KEY] ?? "N/A"}>
            {selectedForDelete?.[CHANGE_VALUE] ?? "N/A"}
          </Descriptions.Item>
        </Descriptions>
      </Modal>

      <div className="configurationContainer">
        <Form
          form={form}
          onFinish={() => setConfirmSaveModalOpen(true)}
          layout="vertical"
        >
          { Object.entries(currentConfigsMap).map(([configId, configMap]) => renderConfigurationItem(configId, configMap)) }
          {isEditing &&
            <Button
              type="dashed"
              onClick={() => onAddClick()}
              style={{width: "100%"}}
              icon={<PlusOutlined />}
            >
              Add field
            </Button>
          }
          <FormActionBar
            disabled={disableSave}
            onUpdateClick={onUpdateClick}
            onHistoryClick={onHistoryClick}
            onHelpClick={() => setShowHelpModal(true)}/>
        </Form>
      </div>

      <Drawer
        title="Configuration History"
        placement="right"
        width={'60%'}
        onClose={() => setHistoryOpen(false)}
        open={historyOpen}
      >
        <Table
          dataSource={deviceConfigHistory}
          rowKey={(record) => record.id}
          columns={deviceConfigHistoryColumns}
          pagination={false}
          loading={deviceConfigHistoryLoading}
        />
      </Drawer>

      <HelperModal modalContent={helpModalContent} setShowInfoModal={setShowHelpModal} showInfoModal={showHelpModal}/>
    </>
  )
}

export default DeviceConfigurationView;