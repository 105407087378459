import { useDispatch, useSelector } from "react-redux";
import { useApi } from "./useApi.hook";
import { getDeviceConfiguration, getDeviceConfigurationHistory } from "../_store/deviceConfigurations";
import { configurationService } from "../_services/configuration";
import { useParams } from "react-router-dom";
import { useOneDevice } from "./devices.hook";
import { useEffect } from "react";

export const useDeviceConfiguration = () => {
  const { configuration, configurationHistory, loading, historyLoading } = useSelector(state => state.deviceConfigurationsReducer);

  const { auth0Token, loading: tokenLoading } = useApi();
  const { deviceId } = useParams();
  const { device } = useOneDevice(deviceId);

  const dispatch = useDispatch();

  useEffect(() => {
    getConfiguration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenLoading, auth0Token, device]);

  const getConfiguration = () => {
    if (!tokenLoading && auth0Token && device && device.chargeboxId && dispatch) {
      dispatch(getDeviceConfiguration({ chargeboxId: device.chargeboxId, auth0Token }));
    }
  }

  const getConfigurationHistory = () => {
    if (!tokenLoading && auth0Token && device && device.chargeboxId && dispatch) {
      dispatch(getDeviceConfigurationHistory({ chargeboxId: device.chargeboxId, auth0Token }));
    }
  }

  const saveConfigurationHandler = (configsToSave) => {
    return configurationService.saveDeviceConfiguration(device?.chargeboxId, configsToSave, auth0Token);
  }

  const deleteConfigurationHandler = (configsToDelete) => {
    return configurationService.deleteDeviceConfiguration(device?.chargeboxId, configsToDelete, auth0Token)
  }

  return {
    configuration,
    configurationHistory,
    loading,
    historyLoading,
    getConfiguration,
    getConfigurationHistory,
    saveConfigurationHandler,
    deleteConfigurationHandler
  }
}