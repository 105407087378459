import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useApi} from "./useApi.hook";
import {getTimeSeriesChartAction} from "../_store/timeSeriesChart";

export const useUpdateChart = (chargeboxId, chartDataName, percent, timeGrainOption, dates) => {
    const [updatedChart, setUpdatedChart] = useState([]);
    const [updatedChartLoading, setUpdatedChartLoading] = useState(true);
    const [updatedChartOptions, setUpdatedChartOptions] = useState({});

    const {auth0Token, loading: tokenLoading} = useApi();
    const dispatch = useDispatch();

    const updateChartOptions = (newChartOptions, timeGrainOptionLabel, dateFromString, dateToString, chartType) => {
        newChartOptions['title'] = {text: `${timeGrainOptionLabel} data from ${dateFromString} to ${dateToString}`}
        newChartOptions['chart']['type'] = chartType;
        if (percent) {
            newChartOptions['yaxis']['max'] = 100;
        }
        if(chartType === "line") {
            newChartOptions['yaxis']['labels'] = {formatter: (value) => value ? value.toFixed(0) + (percent ? '%' : '') : 'No Data'};
            newChartOptions['chart']['toolbar']['export']['csv']['dateFormatter'] = function (timestamp) {
                return new Date(timestamp).toISOString()
            }
        }
        setUpdatedChartOptions(newChartOptions);
    }

    useEffect(() => {
        if (!tokenLoading && auth0Token && dispatch) {
            dispatch(getTimeSeriesChartAction({
                chargeboxId, chartDataName, timeGrainOption, dates, auth0Token
            })).unwrap().then((chart) => {
                setUpdatedChart(chart['series']);
                updateChartOptions(chart['options'], timeGrainOption.label, dates[0].toISOString(), dates[1].toISOString(), chart['options']['chart']['type']);
                setUpdatedChartLoading(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenLoading, auth0Token]);

    const updateTimeSeriesChart = (updatedTimeGrainOption, updatedDates) => {
        setUpdatedChartLoading(true);
        dispatch(getTimeSeriesChartAction({
            chargeboxId, chartDataName, timeGrainOption: updatedTimeGrainOption, dates: updatedDates, auth0Token
        })).unwrap().then((chart) => {
            setUpdatedChart(chart['series']);
            updateChartOptions(chart['options'], updatedTimeGrainOption.label, updatedDates[0].toISOString(), updatedDates[1].toISOString(), chart['options']['chart']['type']);
            setUpdatedChartLoading(false);
        });
    };

    return {
        updatedChart,
        updatedChartLoading,
        updatedChartOptions,
        updateTimeSeriesChart
    };
};