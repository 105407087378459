import { Button, Form, Tooltip } from "antd";
import { HistoryOutlined, EditOutlined, QuestionCircleOutlined, SaveOutlined } from "@ant-design/icons";
import './styles.less';

const tooltipPlacement = "top";

/**
 * Component that appears at bottom of state/configuration global key/device setting forms
 * add, save, and history button
 */
const FormActionBar = ({disabled, disableEdit = false, onUpdateClick, onHistoryClick, onHelpClick}) => {
    return (
        <Form.Item className="formItem btnWrapper">
            {disableEdit ? <></> : <Tooltip placement={tooltipPlacement} title={"Update"}>
                <Button
                    disabled={disableEdit}
                    className="formBtn"
                    icon={<EditOutlined style={{fontSize: "25px"}}/>}
                    onClick={() => onUpdateClick()}
                />
            </Tooltip>}

            <Tooltip placement={tooltipPlacement} title={"Save Updates"}>
                <Button
                    className="formBtn"
                    htmlType="submit"
                    type="primary"
                    disabled={disabled}
                    icon={<SaveOutlined style={{fontSize: "25px"}}/>}
                />
            </Tooltip>

            <Tooltip placement={tooltipPlacement} title={"History"}>
                <Button
                    className="formBtn"
                    icon={<HistoryOutlined style={{fontSize: "25px"}}/>}
                    onClick={() => onHistoryClick()}
                />
            </Tooltip>

            <Tooltip placement={tooltipPlacement} title={"Help"}>
                <Button
                    className="helpBtn"
                    icon={<QuestionCircleOutlined style={{fontSize: "25px"}}/>}
                    onClick={() => onHelpClick()}
                />
            </Tooltip>
        </Form.Item>
    )
}

export default FormActionBar;