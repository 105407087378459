import { useState } from 'react';
import { Button, DatePicker, Spin, Tooltip } from 'antd';
import moment from 'moment';
import Chart from 'react-apexcharts';
import { useUpdateChart } from '_hooks/updateChart.hook';
import TimeGrainOptionsBtn from "../../_components/TimeGrainOptionsButton";
import { SyncOutlined } from "@ant-design/icons";
import { timeGrainOptions } from '_constants';
const { RangePicker } = DatePicker;

const TimeSeriesChart = ({
    percent,
    chargeboxId,
    chartDataName
}) => {

    var initialDates = [moment().subtract(2, 'weeks'), moment()];

    const [dates, setDates] = useState(initialDates);
    const [timeGrainOption, setTimeGrainOption] = useState(timeGrainOptions[1]);
    const [needsRefresh, setNeedsRefresh] = useState(false);

    const {
        updatedChart,
        updatedChartLoading,
        updatedChartOptions,
        updateTimeSeriesChart
    } = useUpdateChart(chargeboxId, chartDataName, percent, timeGrainOptions[1], initialDates);

    const updateTimeGrainOption = (updatedTimeGrainOption) => {
        setTimeGrainOption(updatedTimeGrainOption);
        setNeedsRefresh(true);
    }
    const updateDates = (updatedDates) => {
        setDates(updatedDates);
        setNeedsRefresh(true);
    }

    const refreshChart = () => {
        updateTimeSeriesChart(timeGrainOption, dates);
        setNeedsRefresh(false);
    }

    return (<div style={{ width: "100%" }}>
        {updatedChartLoading ? <Spin size={"small"} /> : <Chart
            height={300}
            type={updatedChartOptions['chart']['type']}
            options={updatedChartOptions}
            series={updatedChart}
        />}
        <div className="timeSeriesChartOptions" style={{ display: "flex", justifyContent: "space-between"}}>
            <TimeGrainOptionsBtn
                onClick={updateTimeGrainOption}
                defaultValue={timeGrainOptions[1]}
                enabled={updatedChartOptions['chart']?.['type'] !== "rangeBar"}
            />
            <RangePicker
                defaultValue={initialDates}
                onChange={(val) => {
                    if (val != null) {
                        updateDates(val)
                    }
                }}
                showTime={{
                    format: 'HH:mm:ss',
                }}
            />
            <div className={'refresh'}>
                <Tooltip placement={'left'} title={'Refresh Chart'}>
                    <Button
                        type="secondary"
                        size="middle"
                        onClick={refreshChart}
                        icon={<SyncOutlined />}
                    >{needsRefresh ? <span
                        style={{ color: "red" }}>Refresh chart to update options</span> : null}</Button>
                </Tooltip>
            </div>
        </div>
    </div>);
};

export default TimeSeriesChart;